@use "./styles/partials/mixin" as *;
@use "./styles/partials/fonts" as *;
@use "./styles/partials/variables" as *;




*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $roboto;
  background-color: $color-background-neutral;
}

//Typography

h1 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
 

  @include tablet {
    font-size: 3rem;
    line-height: 3.25rem;
  }

  @include desktop {
    font-size: 3.5rem;
    line-height: 3.75rem;
  }
}

h2 {
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 600;


  @include tablet {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}

h3 {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
 

  @include tablet {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

h4 {
  font-size: 1.1rem;
  line-height: 1.25rem;
  margin: .75 0;
}

p {
  font-size: 1rem;
  line-height: 1.15rem;
  font-weight: 400;
  margin: .5rem 0;

  @include tablet {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

a {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
}

button {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
}