@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixin" as *;


.search {
  text-align: center;
  background-color: $color-background-primary;

  &__heading {
      margin: auto;
      padding: 6rem 1rem 0 1rem;
      color: $color-title-primary;

      @include tablet {
        font-size: 2.2rem;
        padding: 6rem 2.25rem 0 2.25rem;
      }

      @include desktop {
        font-size: 2.5rem;
        width: 60rem;
      }
  }
  
  &__select {
      font-size: 1.5rem;
      padding: .5rem calc((100vw - 8.5625rem - 1rem - 1rem)/2);
      margin: 2rem 0;
      background-color: $color-background-secondary;
      color: $color-text-standard;
      border: solid 2px $color-text-standard;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;
      text-align-last: center;

      @include tablet {
        padding: .5rem 4rem;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
  }

  &__option {
    text-align: left;
  }

}