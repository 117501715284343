@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

/* responsive breakpoints*/
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1280px;

/* colors primary*/
$color-text-primary: #2F3130;
$color-text-standard: #F7F2E7;
$color-title-primary: #FFFFFF;
$color-background-primary: #4C6E84;
$color-cta-primary: #666060;

/* colors secondary*/
$color-background-secondary: #E18734;
$color-background-neutral: #FEF9F3;
$color-cta-hover: #F3E8C0;
$color-secondary-brand: #EEBC50;

/*font family */
$roboto:'Roboto', sans-serif;
$yaseva : 'Yeseva One', cursive;