@use "../../styles/partials/mixin" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/variables" as *;

.bio {
    padding: 0 1rem;

    @include tablet {
        padding: 0 2.25rem;
    }

    @include desktop {
        padding: 0 calc((100vw - 69rem) / 2);   
    }

    &__container {
        display: flex;
    }

    &__hero-container {

        @include desktop {
            display: flex;
        }
    }

    &__profile-container {
        
        @include tablet {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            padding-top: 5.5rem;
        }

        @include desktop {
            padding-top: 5rem;
            display: inline-block;
            width: 18rem;
        }
    }

    &__chef-img {
        width: calc(100vw - 1rem - 1rem);
        height: calc(100vw - 1rem - 1rem);
        object-fit: cover;
        object-position: top;
        border-radius: 15px;
        margin-top: 5.5rem;

        @include tablet {
            margin-top: 0;
            width: 17.25rem;
            height: 19rem;
        }

        @include desktop {
            width: 18rem;
            height: 18rem;
            border-radius: 50% ;
        }
    }

    &__info-container {
        width: calc(100vw - 1rem - 1rem);

        @include tablet {
            width: calc(100vw - 2.25rem - 2.25rem - 17.25rem - 2rem);
            margin: 0 0 0 2rem;
        }

        @include desktop {
            width: auto;
            margin: 0;
        }
    }

    &__name-cuisine-rating {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            justify-content: flex-start;
        }
    }

    &__chef-name {
        margin: .5rem 0 .25rem 0;
        color: $color-text-primary;
    }

    &__cuisines {
        color: $color-background-primary;
        font-size: 1.05rem;
        margin-top: 0.25rem;
    }

    &__chef-rating {
        font-size: 1.5rem;
        color: $color-secondary-brand;

        @include tablet {
            margin: 0 2.25rem;
        }
    }

    &__long-bio {
        width: calc(100vw - 1rem - 1rem);

        @include tablet {
            width: auto;
        }

        @include desktop {
            width: 36rem;
        }
    }

    &__img-container {

        @include tablet {
            display: flex;
            justify-content: space-between;
        }

        @include desktop {
            margin: 5rem 0 0 18rem;
            height: 25rem;
            position: absolute;
        }

    }

    &__images {
        width: calc(100vw - 1rem - 1rem);
        height: calc(100vw - 1rem - 1rem - 5rem);
        object-fit: cover;
        margin: .5rem 0;
        border-radius: 15px;

        @include tablet {
            width: calc((100vw - 2.25rem - 2.25rem - 2rem) / 4);
            height: calc((100vw - 2.25rem - 2.25rem - 2rem) / 4);
            margin: .25rem 0;
        }

        @include desktop {
            width: 11.75rem;
            height: 15rem;
            padding: 0;
            margin: 2.5rem 0 0 1rem;
        }
    }

    &__body-container {

        @include tablet {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__booking-header {
        margin-top: 2.25rem;

        @include desktop {
            display: none;
        }
    }

    &__booking-container {

        @include tablet {
            display: flex;
            justify-content: space-between;
        }

        @include desktop {
            display: none;
        }
    }

    &__booking-link {
        text-decoration: none;

        @include desktop {
        width: 7.5rem;
    }
    
}

    &__booking-card {
        width: calc(100vw - 1rem - 1rem);
        color: $color-text-primary;
        border: 2px solid $color-background-secondary;
        border-radius: 15px;
        padding: 0.25rem .5rem;
        text-align: center;
        margin-bottom: 1rem;
        transition: .5s background-color, .5s color;

        &:hover {
            background-color: $color-background-secondary;
            color: $color-title-primary;
        }

        @include tablet {
            width: calc((100vw - 2.25rem - 2.25rem - 2rem) / 3);
        }
    }

    &__review-header {
        margin-top: 2rem;
    }

    &__reviews-container {
        
        @include tablet {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @include desktop {
            display: inline-block;
        }
    }

    &__review-card {
        width: calc(100vw - 1rem - 1rem);
        border: 1px solid $color-cta-primary;
        border-radius: 10px;
        background-color: $color-text-standard;
        padding: .25rem 1rem;
        margin: 1rem 0;
        
        @include tablet {
            width: calc((100vw - 2.25rem - 2.25rem - 1rem) / 2);
            margin: .5rem 0;
            margin-bottom: 1rem;
        }

        @include desktop {
            width: 36rem;
        }
    }

    &__review-info {
        display: flex;
        justify-content: space-between;
    }

    &__icon-reviewer {
        display: flex;
        align-items: center;
    }

    &__user-icon {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: cover;
        object-position: top;
        margin-right: .5rem;
    }

    &__reviewer {
        font-size: 1rem;
        font-weight: 600;
    }

    &__date {
        align-self: end;
    }

    &__booking {
        border-top: 1px solid rgb(215, 214, 214);
        position: fixed;
        bottom:0;
        left: 0;
        width: 100vw;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include desktop {
            flex-direction: column;
            justify-content: center;
            position: sticky;
            top: 5rem;
            width: 32rem;
            height: 21rem;
            border: 1px solid rgba(0, 0, 0, 0.034);
            border-radius: 15px;   
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            margin: 25rem 0 1rem 2rem;
        }
    }


    &__book-heading-hidden {
        display: none;
        
        @include desktop {
            display: inline-block;
            margin-top: 0;
        }
    }

    &__book-info {
        margin-left: 1rem;

        @include tablet {
            margin-left: 2.25rem;
        }

        @include desktop {
            display: none;
        }
    }

    &__book-name {
        margin: .75rem 0 0 0;
    }

    &__book-location {
        color: $color-background-primary;
        font-size: 1.05rem;
        margin: 0.25rem 0 .75rem 0;
    }

    &__booking-type {
        margin: .5rem 0;

        @include desktop {
            margin: .25rem;
            padding-right: 1rem;   
        }
    }
    &__book-link {
        text-decoration: none;
        text-align: center;
        color: white;
        margin-right: 1rem;

        @include tablet {
            margin-right: 2.25rem;
        }
    }

    &__book-button {
        background-color: $color-background-secondary;
        border-radius: 10px;
        width: 7.5rem;
        margin: auto;
        margin-right: 1rem;

        @include tablet {
            margin-right: 2.25rem;
        }
    }

    &__book-title {
        padding: .75rem 1rem;
        color: $color-title-primary;
        margin: 0;
    }

    &__booking-link-desktop {
        display: none;

        @include desktop {
            display: inline-block;
            text-decoration: none;
        }
    }

    &__booking-card {
        
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28rem;
        }
    }

}