@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixin" as *;


.header {
    background-color: $color-title-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    padding: .75rem 1rem;
    z-index: 100;
    border-bottom: 1px solid rgb(215, 214, 214);

    @include tablet {
        padding: .75rem 2.25rem;
    }

    @include desktop {
        padding: .5rem calc((100vw - 69rem)/2);
    }

    &__link {
        text-decoration: none;
        color: rgb(127, 125, 125);
        text-align: center;
        filter: contrast(0);

        &:hover {
            filter: invert(76%) sepia(33%) saturate(5259%) hue-rotate(338deg) brightness(94%) contrast(86%);
        }

    }

    &__logo-link {
        display: none;

        @include tablet {
            display: block;
            text-decoration: none;
            color:$color-cta-primary ;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            width: 35%;
        }

        @include desktop {
            width: 30%;
        }
    }

    &__logo {
        @include tablet {
        font-family: $yaseva;
        font-size: 2rem;
        margin: 0;
        }

        &:hover {
            color:$color-background-secondary ;
        }
    }

    &__icon {
        height: 1.5rem;
        cursor: pointer;
    }

    &__title {
        margin: 0;
        line-height: 1rem;
        list-style: none;
    }
}
//decorate the active class name link 
.active {
   text-decoration: none;
   
   color: black;
            filter: invert(76%) sepia(33%) saturate(5259%) hue-rotate(338deg) brightness(94%) contrast(86%);

}



