@use "../../styles/partials/mixin" as *;
@use "../../styles/partials/variables"as *;

.booking {
  padding: 1rem;

  @include tablet {
    padding: 2.25rem;
  }

  @include desktop {
    padding: 3rem;
    background-color: $color-background-primary;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  }

  &__wrapper {
    background-color: $color-title-primary;
    margin: 1rem auto 0 auto;

    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 71rem;
      border-radius: 15px;
      margin: 3rem auto 0 auto;
      padding: 0;
    }
  }

  &__container {

    @include desktop {
      width: 30rem;
      margin-left: 3rem;
    }
  }

  &__title {
    font-size: 2rem;
    color: $color-text-primary;
    text-align: center;
    padding-top: 2rem;
    font-family: $roboto;

    @include desktop {
      padding-top: .5rem;
    }
  }
  

  
  &__input {
    padding: 10px;
    background: $color-title-primary;
    border-radius: 10px;
    outline: none;
    color: $color-text-primary;
    border: 2px solid $color-background-secondary;
    width: 100%;
    font-family: $roboto;
  }

  &__text-area {
    padding: 10px;
    background: $color-title-primary;
    border-radius: 10px;
    outline: none;
    color: $color-text-primary;
    border: 2px solid $color-background-secondary;
    resize: none;
    width: 100%;
    height: 5rem;
    font-family: $roboto;
  }


  &__label {
    font-size: 14px;
    padding: 0px 5px;
    color: $color-text-primary;
    font-family: 600;
    margin-top: 1rem;
    display: block;
  }

  &__img {
    width: 100%;
    height: 50vw;
    object-fit: cover;
    object-position: 50% 15%;

    @include desktop {
      height: 55rem;
      width: 35rem;
      object-fit: cover;
      object-position: top;
      border-radius: 0 15px 15px 0;
      overflow: hidden;
    }
  }

  &__btn {
    cursor: pointer;
    border-radius: 5px;
    background-color: $color-background-secondary;
    border: none;
    color: $color-title-primary;
    padding: .25rem 1rem;
    margin: 1rem 0 1rem 0;
    width: 100%;
    height: 2.4688rem;
    text-transform: uppercase;

    @include tablet {
      width: 30%;
      margin: 0 calc((100vw - 30% - 2.25rem - 2.25rem) / 2);
      margin-bottom: 2rem;
      margin-top: 1rem;
    }

    @include desktop {
      width: 8rem;
      margin: 1rem 11rem 1rem 11rem;
    }

    &:hover{
      background-color: $color-cta-primary;
  }

    }
&__error {
    color: red;
    font-size: 0.75rem;
    font-weight: 400;
    text-align: start;
    font-style: italic;
    margin: 0;
    display: flex;
  }
}


  
  



