@use "../../styles/partials/mixin" as *;
@use "../../styles/partials/variables" as *;

.modal{
  background-color: rgba(35, 41, 64, 0.75);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet {
      padding: 0 3rem;
  }

  @include desktop {
    padding: 0;
  }
  &__text {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 1rem;
      margin: 0 .5rem;
      background-color: $color-background-neutral;
      border-radius: 15px;
      @include tablet {
          height:auto;
          width: auto;
      }
      @include desktop {
          width: 40rem;
          margin: 0 auto;
      }
  }

  &__container {

      @include tablet {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
          padding-left: 2.5rem; 
          padding-right: 4rem;
      }
  }
  &__heading {
    color: $color-text-primary;
      font-size: 1.6rem;
      line-height: 2.25rem;
      font-weight: 600;
      margin: 0;
      @include tablet {
      font-size: 2rem;
      line-height: 2.5rem;
      }
  }

  &__confirmation {
    padding-bottom: 1rem;
    font-size: 1rem;
  }

  &__title {
    font-family: $yaseva;
    color:$color-text-primary ;
  }

  &__confirm {
    cursor: pointer;
    padding: .5rem 2rem;
    text-align: center;
   text-decoration: none;
   background-color: $color-background-secondary;
   color: $color-background-neutral; 
   text-transform: uppercase;
   border-radius: 10px;
   margin-bottom: 1rem;
   font-size: 1rem;
   @include tablet {
    padding: .5rem 4rem;
   }
   
  }


}