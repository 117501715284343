@use "./variables" as *;

/* normal roboto*/
@font-face {
  font-family: $roboto;
  src: url(/assets/fonts/Roboto-Regular.woff) format("woff"), 
  url(/assets/fonts/Roboto-Regular.woff2) format("woff2");
  font-weight: 400;
}


/* medium roboto */
@font-face {
  font-family: $roboto;
  src: url(/assets/fonts/Roboto-Medium.woff) format("woff"),
  url(/assets/fonts/Roboto-Medium.woff2) format("woff2");
  font-weight: 600;
}

