@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixin" as *;


.main {
    
    &__card-link {
         text-decoration: none;
         width: calc((100vw - 3.25rem - 3.25rem - 2rem)/2);
    }

    &__chef-card {

        @include tablet {
            width: calc((100vw - 2.25rem - 2.25rem - 2rem) / 2);
        }

        @include desktop {
            width: 20rem;
        }
    }

    &__info-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 .5rem;

        @include tablet {
            margin: 0 .75rem;
        }
    }

    &__name-cuisine {
        text-align: left;
    }

    &__chef-img {
        height: calc(100vw - 1rem - 1rem);
        max-height: 31rem;
        width: calc(100vw - 1rem - 1rem);
        object-fit: cover;
        object-position: top 10% left 0;
        margin-top: .5rem;
        padding: 0;
        border-radius: 15px;

        @include tablet {
            height: calc((100vw - 2.25rem - 2.25rem - 2rem) / 2);
            width: calc((100vw - 2.25rem - 2.25rem - 2rem) / 2);
        }

        @include desktop {
            height: 17.5rem;
            width: 20rem;
        }
    }

    &__chef-name {
        margin: .5rem 0 .25rem 0;
        color: $color-text-primary;
    }

    &__cuisines {
        margin: 0;
        color: $color-background-primary;
    }

    &__chef-rating {
        font-size: 1.5rem;
        margin: 0;
        color: $color-secondary-brand;
    }

    &__chef-bio {
        text-align: left;
        margin: .5rem .5rem 1.75rem .5rem;
        color: $color-cta-primary;   

        @include tablet {
            margin: .5rem .75rem 1.75rem .75rem; 
        }
    }
}