@use "../../styles/partials/mixin" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: $color-text-primary;
  width: 100%;
  padding: 1rem 1rem 6rem 1rem;

  @include tablet {
    padding: 1rem 2.25rem 6rem 2.25rem;
  }

  @include desktop {
    padding: 1rem calc((100vw - 69rem)/2);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    text-decoration: none;
  }
  
  &__right {
    display: flex;
    justify-content: space-between;
  }
  
  &__icon {
    padding-left: 1rem;
  }

  &__copyright {
    color: $color-title-primary;
    text-align: center;
    font-size: .75rem;
    @include tablet {
      font-size: 1rem;
    }
  }

  &__logo {
    color: $color-title-primary;
    font-family: $yaseva;
    font-size: 1.2rem;
    @include tablet {
      font-size: 1.5rem;
    }
  }
}